<template>
  <div class="index-overview"> 
    <div class="recent-purchases">
      <RecentPurchasesVue />
    </div>
    <div class="year-to-date">
      <YearToDateVue />
    </div>
  </div>
</template>

<script>
import RecentPurchasesVue from './blocks/RecentPurchases'
import YearToDateVue from './blocks/YearToDate'

export default {
  name: 'IndexOverview',

  components: {
    RecentPurchasesVue,
    YearToDateVue,
  }
}
</script>

<style lang="scss">
.index-overview {
  font-size: 20px;
  padding: $padding;
  display: grid;
  grid-template-areas:
    "left right";
  grid-template-columns: 1fr 1fr;
  gap: $margin;

  @media screen and (max-width: $mobile-break) {
    grid-template-areas: "one";
    grid-template-columns: 1fr;
    gap: unset;
  }
}
</style>
