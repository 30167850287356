<template>
  <div class="business-list-wrapper">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>

    <div class="list-cont col35-65" v-else>
      <div class="list-header left">
        <div class="add-business-cont">
          <div class="list-header left">
            <CreateBtnAndModal
              @create_modal_save="onSave"/>
          </div>
        </div>
      </div>

      <div class="list-wrapper right">
        <div 
          v-for="business in businessList" 
          :key="business.id"
          class="list-item">
          <router-link :to="{ name: 'business', params: { id: business.id }}" class="list-item-link">
            <ListCard 
              :model="business">
            </ListCard>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ListCard from './ListCard'
import LoadingWidget from './../../../common/elements/LoadingWidget'
import CreateBtnAndModal from './CreateBtnAndModal'
import { postUrl } from './../utils/fetch_helpers'
import { useBusinessStore } from './../stores/BusinessStore'

export default {
  name: 'BusinessList',
  
  setup() {
    const businessSet = useBusinessStore()

    return {
      businessSet
    }
  },

  data() {
    return {
      lineCenterKey: 'name',
      loading: true,
      newBusinessName: '',
      newBusinessDefCat: '',
      focus: true,
    }
  },

  computed: {
    businessList() {
      return this.businessSet.getSet.map(b => {
        return {
          ...b,
          cardData: {
            center: b.name
          }
        }
      })
    }
  },

  methods: {
    onAddBusinessClick() {
      this.showModal()
    },

    showModal() {
      this.$refs.modal.show()
      //this.$refs.newBusinessName.focus()
      this.$refs.nameInput.focusInput()
      console.log(this)
    },

    hideModal() {
      this.$refs.modal.hide()
    },

    // events
    onClickCancel() { this.hideModal() },

    onClickSave() {
      const data = {
        //userId: this.store.userId,
        name: this.newBusinessName
      }
      console.log(this)
      console.log(data)
      postUrl(this.store.businessUrl, {
        body: JSON.stringify(data)
      })
    },

    onSave(data) {
      console.log('bus save', data)
    }
  },

  created() {
    this.businessSet.fetch().then(() => {
      this.loading = false
    })
  },

  components: {
    ListCard,
    LoadingWidget,
    CreateBtnAndModal,
  }
}
</script>

<style lang="scss" scoped>
.business-list-wrapper {
  height: 100%;
}
</style>
