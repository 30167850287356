<template>
  <div class="category-list-wrapper">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>

    <div class="list-cont" v-else>
      <div class="list-header left">
        <CreateBtnAndModal
          @create_modal_save="onSave"/>
      </div>

      <div class="list-wrapper right">
        <div 
          v-for="category in categoryList" 
          :key="category.id"
          class="list-item">
          <router-link :to="{ name: 'category', params: { id: category.id }}" class="list-item-link">
            <ListCard 
              :model="category">
            </ListCard>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListCard from './ListCard'
import LoadingWidget from './../../../common/elements/LoadingWidget'
import CreateBtnAndModal from './CreateBtnAndModal'
import { useCategoryStore } from './../stores/CategoryStore'

export default {
  name: 'CategoryList',

  setup() {
    const categorySet = useCategoryStore()
    return {
      categorySet
    }
  },

  props: {
  },

  data () {
    return {
      loading: true,
    }
  },

  computed: {
    categoryList() {
      return this.categorySet.getSet.map(c => {
        return {
          ...c,
          cardData: {
            center: c.name
          }
        }
      })
    }
  },

  methods: {
    onSave(data) {
      console.log('category save', data)
    }
  },

  created() {
    this.categorySet.fetch().then(() => {
      this.loading = false
    })
  },

  components: {
    ListCard,
    LoadingWidget,
    CreateBtnAndModal,
  }
}
</script>

<style lang="scss" scoped>
.category-list-wrapper {
  height: 100%;
}
</style>
