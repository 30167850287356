import { get, post, put } from './../../../common/utils/fetch_helpers';
import { getLocalValue } from './storage';

export const getUrl = (url, opts = {}) => {
  const at = getLocalValue('at');
  return get(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Token ${at}`,
    },
    ...opts,
  });
};

export const postUrl = (url, data, opts = {}, headers = {}) => {
  const at = getLocalValue('at');
  console.log('posturl', headers)
  console.log('posturl', url, data, opts)
  return post(url, data, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Token ${at}`,
      ...headers,
    },
    body: data,
    ...opts,
  }).then((r) => {
    return r
  });
};

export const putUrl = (url, data, opts = {}, headers = {}) => {
  const at = getLocalValue('at');
  return put(url, data, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Token ${at}`,
      ...headers,
    },
    body: data,
    ...opts,
  });
}
