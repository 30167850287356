<template>
  <div class="list-card-cont card flex flex-center flex-column">
    <div
      class="list-card-line-data flex flex-center"
      @click="clickListCardLine"
    >
      <div v-if="left" class="line-left">{{ left }}</div>
      <div v-if="center" class="line-center">{{ center }}</div>
      <div v-if="right" class="line-right">{{ right }}</div>
      <i
        v-if="expandable"
        class="material-icons"
        :class="{ collapsed: !expanded, expanded: expanded }"
      >
        expand_more
      </i>
    </div>
    <div
      v-if="expandable"
      class="child-left list-card-details"
      :class="{ collapsed: !expanded, expanded: expanded }"
      :style="style"
      ref="details"
    >
      <div class="card-header">{{ details.header }}</div>
      <div class="card-text">{{ details.text }}</div>
      <div v-if="details" class="card-link">
        <router-link
          :to="{ name: details.link.name, params: details.link.params }"
        >
          {{ details.link.text }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListCard',
  props: {
    model: Object,
    expandable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      style: {
        height: 0,
      },
      expanded: false,
    };
  },

  computed: {
    collapseId() {
      return `fullcard-${this.model.id}`;
    },

    left() {
      return this.model.cardData.left;
    },

    right() {
      return this.model.cardData.right;
    },

    center() {
      return this.model.cardData.center;
    },

    details() {
      return this.model.cardData.details;
    },
  },

  methods: {
    clickListCardLine() {
      if (this.expandable) this.expandDetails();
    },
    expandDetails() {
      if (!this.expanded) {
        this.style.height = this.getCardHeight();
      } else {
        this.style.height = 0;
      }
      this.expanded = !this.expanded;
    },
    getCardHeight() {
      const cardContents = this.$refs.details.children;
      const height = this.$_.reduce(
        cardContents,
        (a, el) => (a += el.offsetHeight),
        0
      );
      return `${height}px`;
    },
  },

  created() {},

  mounted() {},
};
</script>

<style lang="scss">
.list-card-cont {
  width: 100%;
  margin: $margin 0;
  border-radius: $border-radius;

  .list-card-line-data {
    cursor: pointer;
    width: 100%;

    .line-left {
    }

    .line-center {
      padding: 0 $padding;
    }

    .line-right {
      margin-left: auto;
    }

    i.expanded {
      transition: all 0.5s;
      transform: rotate(180deg);
    }

    i.collapsed {
      transition: all 0.5s;
    }
  }

  .list-card-details {
    transition: all 0.5s;

    .card-header {
    }

    .card-text {
    }

    .card-link {
    }

    :first-child {
      padding-top: $padding;
    }

    &.collapsed {
      height: 0;
      overflow: hidden;
    }

    &.expanded {
      overflow: hidden;
    }
  }
}
</style>
