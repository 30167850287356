<template>
  <div class="purchase-list-wrapper">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>

    <div class="list-cont" v-else>
      <div class="list-header left">
        <ListControlsVue />
      </div>

      <div class="list-wrapper right" @scroll="scrollCheck">
        <ListCard
          v-for="purchase in purchaseList"
          :key="purchase.id"
          :model="purchase"
          :expandable="true"
        >
        </ListCard>
        <LoadingWidget type="bar" ref="loadingMore" class="hide"></LoadingWidget>
      </div>
    </div>
  </div>
</template>

<script>
import ListCard from './ListCard'
import LoadingWidget from './../../../common/elements/LoadingWidget'
import ListControlsVue from './ListControls'
import { usePurchaseStore } from './../stores/PurchaseStore'

export default {
  name: 'PurchaseList',

  setup() {
    const purchaseSet = usePurchaseStore();

    return {
      purchaseSet,
    };
  },

  data() {
    return {
      detailKeys: [
        'desc',
        {
          key: 'details',
          isLink: true,
        },
      ],
      loading: true,
    };
  },

  computed: {
    purchaseList() {
      return this.purchaseSet.getSet.map((p) => ({
        ...p,
        cardData: {
          left: p.date,
          right: `$${p.amount}`,
          center: p.business && p.business.name,
          details: {
            header: p.category && p.category.name,
            text: this.$_.truncate(p.desc),
            link: {
              name: 'purchase',
              text: 'See Details',
              params: { id: p.id },
            },
          },
        },
      }));
    },
  },

  methods: {
    scrollCheck(e) {
      const t = e.target
      if (t.scrollTop >= (t.scrollHeight - t.offsetHeight) && !!this.purchaseSet.getNextLink) {
        this.showLoading()
        this.purchaseSet.fetch(this.purchaseSet.getNextLink, true)
          .then(() => {
            this.hideLoading()
          })
      }
    },

    showLoading() {
      this.$refs.loadingMore.show()
    },

    hideLoading() {
      this.$refs.loadingMore.hide()
    },
  },

  created() {
    this.purchaseSet.fetch().then((r) => {
      this.loading = false;
      return r;
    });
  },

  components: {
    ListCard,
    ListControlsVue,
    LoadingWidget,
  },
};
</script>

<style lang="scss">
.purchase-list-wrapper {
  height: 100%;
  //padding: 0 $padding;
}
</style>
