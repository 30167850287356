const setLocalValue = exports.setLocalValue = (key, value) => {
  localStorage.setItem(key, value)
}

exports.getLocalValue = (key) => {
  return localStorage.getItem(key)
}

exports.setLocalValues = (data) => {
  Object.keys(data).forEach((k) => {
    setLocalValue(k, data[k])
  })
}
