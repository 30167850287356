<template>
  <div class="purchase-card-cont flex flex-center flex-column">
    <LoadingWidget v-if="loading" type="bar" ref="loading"></LoadingWidget>

    <div v-else class="purchase">
      <div  class="purchase-line-data flex flex-center">
        <div class="purchase-date">{{model.date}}</div>
        <div class="purchase-amount child-right">${{model.amount}}</div>
      </div>
      <div class="child-left purchase-details" ref="details">
        <router-link :to="{ name: 'business', params: {id:model.business.id} }">
          <div class="purchase-business">{{model.business.name}}</div>
        </router-link>

        <router-link :to="{ name: 'category', params: {id: model.category.id} }">
          <div class="purchase-category">{{model.category.name}}</div>
        </router-link>

        <div class="purchase-desc">{{model.desc}}</div>

        <div class="purchase-controls">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingWidget from './../../../common/elements/LoadingWidget'
import { getUrl } from './../utils/fetch_helpers'
const PURCHASE_URL = `${process.env.VUE_APP_PIPES}/finances/purchases/`

export default {
  name: 'PurchaseItem',
  props: ['id'],
  data () {
    return {
      modelId: this.$route.params.id,
      model: undefined,
      loading: true
    }
  },
  computed: {
  },
  methods: {
    fetchModel() {
      return getUrl(`${PURCHASE_URL}${this.modelId}/`)
    }
  },
  created() {
    this.fetchModel().then(r => {
      this.model = r;
      this.loading = false;
    })
  },
  components: {
    LoadingWidget
  }
}
</script>

<style lang="scss">
.purchase-card-cont {
  width: 100%;
  margin: $margin 0;

  .purchase {
    width: 50%;

    .purchase-line-data {
      width: 100%;

      .purchase-amount {
      }
    }

    .purchase-business,
    .purchase-category {
      font-weight: $bold;
    }

    .purchase-details {
      transition: all 0.5s;

      :first-child {
        padding-top: $padding;
      }
    }
  }
}
</style>
