<template>
  <div class="category-item-cont flex">
    <LoadingWidget type="bar" ref="loading"></LoadingWidget>

    <div>
      <div v-if="loaded" class="category-details" ref="detailsCont">
        <div>{{ category.name }}</div>
        <div>{{ category.color }}</div>
        <div>{{ category.priority }}</div>
        <div>{{ category.group }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingWidget from './../../../common/elements/LoadingWidget'
import { getUrl } from './../utils/fetch_helpers'
const CATEGORY_URL = `${process.env.VUE_APP_PIPES}/finances/categories/`

export default {
  name: 'CategoryItem',
  props: ['id'],
  data () {
    return {
      category: undefined,
      loaded: false
    }
  },
  methods: {
    showDetails(r) {
      this.category = r;
      this.$refs.loading.hide()
      this.loaded = true
    }
  },
  created () {
    getUrl(`${CATEGORY_URL}${this.id}`).then(r => {
      this.showDetails(r)
    })
  },
  components: {
    LoadingWidget
  }
}
</script>

<style lang="scss" scoped>
</style>
