<template>
  <div class="business-item-cont flex flex-center">
    <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    <div>
      <div v-if="loaded" class="business-details" ref="detailsCont">
        <div>{{ business.name }}</div>
        <div>{{ business.spent }}</div>
        <div>{{ business.default_cat }}</div>
        <div>{{ business.user.username }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import LoadingWidget from './../../../common/elements/LoadingWidget'
import { getUrl } from './../utils/fetch_helpers'
const BUSINESS_URL = `${process.env.VUE_APP_PIPES}/finances/businesses/`

export default {
  name: 'BusinessItem',

  props: {
    businessObj: Object,
    detailView: {
      type: Boolean,
      default: true
    },
    id: [String, Number]
  },

  data () {
    return {
      business: this.businessObj,
      loaded: false,
    }
  },

  methods: {
    fetchBusiness() {
      return getUrl(`${BUSINESS_URL}${this.id}/`)
    },
    showDetails(r) {
      this.business = r
      this.loaded = true
      this.$refs.loading.hide()
    }
  },

  //TODO watch: {$route},
  created() {
  },

  mounted() {
    if (this.detailView) {
      this.fetchBusiness().then(r => {
        this.showDetails(r)
      })
    }
  },

  components: {
    LoadingWidget
  }
}
</script>

<style lang="scss" scoped>
.business-item-cont {
  margin: $margin-small;
}
</style>
