import { defineStore } from 'pinia';
import { getUrl, postUrl } from './../utils/fetch_helpers';
import { post } from './../../../common/utils/fetch_helpers';
import * as storage from './../utils/storage.js';

const checkTokenUrl = `${process.env.VUE_APP_PIPES}/auth/check`;
const loginUrl = `${process.env.VUE_APP_AUTH}/login`;
const logoutUrl = `${process.env.VUE_APP_AUTH}/logout`;
const INVALID_TOKEN = 'Invalid token.';

export const useCoreUserStore = defineStore('coreUser', {
  state: () => ({
    userId: 0,
    res: [],
  }),

  getters: {
    getUserId: (state) => state.userId,
  },

  actions: {
    login(data) {
      return post(loginUrl, JSON.stringify(data)).then((r) => {
        if (r.status < 400 || r.detail !== INVALID_TOKEN) {
          storage.setLocalValues({ at: r.token, userId: r.user.id });
          this.userId = r.user.id;
        }
        return r;
      });
    },

    checkToken() {
      return getUrl(checkTokenUrl).then((res) => {
        this.res = res;
        return res;
      });
    },

    logout() {
      return postUrl(logoutUrl, JSON.stringify({}));
    },
  },
});
