<template>
  <div class="modal-wrapper hide" ref="modal" @keyup.esc="hide()">
    <div class="modal-header">
      <div class="header-left"></div>
      <div class="header-middle">{{header}}</div>
      <div class="header-right">
        <i class="material-icons close" @click="hide()">close</i>
      </div>
    </div>
    <div class="modal-body">{{body}}</div>
    <div class="modal-footer">
      <div class="footer-text">{{footer}}</div>
      <div class="footer-btn-cont"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'CalendarModal',
  props: {
    modalOptions: Object
  },
  data() {
    return {
    }
  },
  computed: {
    header() {
      return this.modalOptions.header
    },
    body() {
      return this.modalOptions.body
    },
    footer() {
      return this.modalOptions.footer
    }
  },
  methods: {
    show() {
      this.$refs.modal.classList.remove('hide')
    },
    hide() {
      this.$refs.modal.classList.add('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../common/styles/modal";
</style>
