import { createWebHistory, createRouter } from 'vue-router'

import IndexOverview from './components/IndexOverview'
import PurchaseList from './components/PurchaseList'
import PurchaseItem from './components/PurchaseItem'
import BusinessList from './components/BusinessList'
import BusinessItem from './components/BusinessItem'
import CategoryList from './components/CategoryList'
import CategoryItem from './components/CategoryItem'
import MonthVue from './components/Month'
import userRoutes from './components/user/routes'

const routes = [
  { 
    path: '/', 
    name: 'index',
    component: IndexOverview 
  },
  {
    path: '/login',
    name: 'login',
    component: IndexOverview
  },
  { 
    path: '/purchases', 
    name: 'purchases',
    component: PurchaseList 
  },
  { 
    path: '/purchases/:id', 
    name: 'purchase',
    component: PurchaseItem,
    props: true
  },
  { 
    path: '/businesses', 
    name: 'businesses',
    component: BusinessList 
  },
  { 
    path: '/businesses/:id', 
    name: 'business',
    component: BusinessItem,
    props: true
  },
  { 
    path: '/categories', 
    name: 'categories',
    component: CategoryList
  },
  { 
    path: '/categories/:id', 
    name: 'category',
    component: CategoryItem,
    props: true
  },
  {
    path: '/purchases/:year/:month',
    name: 'monthly',
    component: MonthVue,
    props: true
  },
  /*{
    path: '/.catchAll(.*)',
    name: 'NotFound',
    component: 'NotFoundComponent'
  },*/
  ...userRoutes
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
