import { defineStore } from 'pinia'
import { getUrl } from '../utils/fetch_helpers'
import { useCoreUserStore } from './CoreStore'
import axios from 'axios'
//import { queryParams } from '../../../common/utils/fetch_helpers'

const URL = `${process.env.VUE_APP_PIPES}/finances/purchases/`

export const usePurchaseStore = defineStore('purchaseSet', {
  state: () => ({
    set: [],
    count: 0,
    previous: undefined,
    next: undefined,
    fetched: false,
  }),

  getters: {
    getSet: (state) => state.set,
    getNextLink: (state) => state.next,
  },

  actions: {
    fetch(url, force = false) {
      if (this.fetched && !force) {
        return Promise.resolve()
      }
      if (!url) url = URL

      return getUrl(url).then((res) => {
        this.set = [...this.set, ...res.results]
        this.count = res.count
        this.previous = res.previous
        this.next = res.next
        this.fetched = true
        return res
      })
    },

    create(data) {
      return axios.post(
        URL, 
        {...data, user_id: useCoreUserStore().userId},
        {
          headers: {'content-type': 'application/json'},
          withCredentials: true
        }
      ).then((res) => {
        if (res.status >= 400) {
          return {status: res.status, message: res.statusText}
        } else {
          this.set.unshift(res.data)
          this.reset()
        }
        return res
      })
    },

    reset() {
      this.fetched = false
    },
  },
})
