<template>
  <div class="month-cont">
    <CalendarVue :year="year" :month="monthNum"></CalendarVue>
  </div>
</template>

<script>
import CalendarVue from './Calendar'

export default {
  name: 'MonthVue',
  props: [
    'month',
    'year'
  ],
  data() {
    return {
    }
  },
  computed: {
    monthNum() {
      return parseInt(this.month) || new Date().getMonth()
    }
  },
  created() {
  },
  components:{
    CalendarVue
  }
}

</script>

<style lang="scss" scoped>
.month-cont {
  height: 100%;
}
</style>
