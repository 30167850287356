<template>
  <div class="calendar-cont">
    <div class="calendar-label">{{monthName}}</div>
    <div class="calendar-header">
      <div v-for="(day, index) in dayLabels"
        :key="index"
        class="day-header">
        {{day}}
      </div>
    </div>

    <div class="calendar-body">
      <div class="day-block"
        v-for="(day, index) in days"
        :key="index"
        :class="day.cssClasses">
        <div class="day-date-label">{{day.date}}</div>
        <div class="day-block-data">
          <div 
            v-for="(blob, index) in day.data"
            :id="blob.id"
            :key="index"
            :class="blob.classes"
            :style="{backgroundColor: blob.color}"
            @click="clickDataBlob(blob, $event)"
            class="data-blob">

              {{blob.text}}
            
          </div>
        </div>
      </div>
    </div>
    
    <CalendarModal :modalOptions="modalOptions" ref="modal"/>
  </div>
</template>

<script>
import calendar from './../../../common/utils/calendar_helpers'
import CalendarModal from './CalendarModal'
//import { getUrl } from './../utils/fetch_helpers'
//const PURCHASE_URL = `${process.env.VUE_APP_PIPES}/finances/purchases/`

export default {
  name: 'CalendarVue',
  props: {
    year: String,
    month: Number,
    today: {
      type: Object,
      default: new Date() 
    }
  },
  data () {
    return {
      dayLabels: calendar.DAYS,
      modalOptions: {}
    }
  },
  computed: {
    jsMonth() { return parseInt(this.month) - 1 },
    days() {
      return calendar.getDaysArray(this.year, this.jsMonth, this.today.getDate())
    },
    monthName() {
      return calendar.MONTHS[this.jsMonth].name
    }
  },
  methods: {
    getMonthlyData() {
      return [
        {id: 1,text: 'test', fullText: 'blah blah', color: '#123456', day: 3, month: 2},
        {id: 2,text: 'two', fullText: 'blahahadsf;lasd;',  color: '#957292', day: 9, month: 2},
        {id: 3,text: 'o', fullText: 'blah', color: '#957b9a', day: 19, month: 2},
        {id: 4,text: '3hga', fullText: 'bla asdfawegh', color: '#9a7b9a', day: 19, month: 2},
        {id: 5,text: 'this ', fullText: 'bl afsdfkah', color: '#997b9a', day: 19, month: 2},
        {id: 6,text: 'nmroe', fullText: 'blah afsdfk', color: '#117b9a', day: 19, month: 2},
      ].map(v => {
        if (v.month === this.month) this.days[v.day].data.push(v)
      })
      //return getUrl(`${PURCHASE_URL}/${this.year}/${this.month}`);
    },
    clickDataBlob(blob) {
      this.modalOptions = {
        header: `${blob.day} ${blob.month}`,
        body: blob.fullText
      }
      this.$refs.modal.show()
    }
  },
  created() {
    this.getMonthlyData()
  },
  components: {
    CalendarModal
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../common/styles/calendar";
</style>
